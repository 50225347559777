import {PaymentStoreProps} from '../../types/app.types';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService} from '../services/CheckoutService';

export type PaymentStoreConfig = {
  flowAPI: ControllerFlowAPI;
  checkoutService: CheckoutService;
};

export class PaymentStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;

  constructor({flowAPI, checkoutService}: PaymentStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
  }

  public toProps(): PaymentStoreProps {
    return {
      cashierConfiguration: {
        locale: this.flowAPI.translations.config.language,
        appId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
        appInstanceId: this.flowAPI.controllerConfig.appParams.instanceId,
        amount: `${this.checkoutService.checkout.payNowTotalAfterGiftCard.amount}`,
        currency: this.checkoutService.checkout.currency!,
        msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '',
        appInstance: this.flowAPI.controllerConfig.appParams.instance,
        country: 'US',
        visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
        siteOwnerId: this.flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
        lang: this.flowAPI.translations.config.language,
        isPrimaryLanguage: true, // TODO!
        // isMerchantContext?: boolean;
        viewMode: 'Site',
        isSignedInUser: false,
        contactId: this.checkoutService.checkout.buyerInfo.contactId,
      },
    };
  }
}
